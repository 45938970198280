import React from 'react';
import { PedidoVendaContent } from './PedidoVendaContent';
import { PedidoVendaContextProvider } from './PedidoVendaContext';
import { TransmissaoProvider } from '~/components/Nfe/TransmissaoNFe/Transmissao';

export const PedidoVenda: React.FC = () => {
  return (
    <TransmissaoProvider>
      <PedidoVendaContextProvider>
        <PedidoVendaContent />
      </PedidoVendaContextProvider>
    </TransmissaoProvider>
  );
};
