import React, { useContext, useState } from 'react';
import { ButtonForm, Container } from './styles';
import Search from '~/components/Search';
import FormDefault from '~/components/FormDefault';
import { loadingContext } from '~/context/loading';
import { usePedidoVenda } from './PedidoVendaContext';

import { Tabs, Tab, Spinner } from 'react-bootstrap';
import { GridRowParams } from '@material-ui/data-grid';
import { Pedido } from './screens/pedido';
import { Financeiro } from './screens/financeiro';
import { Entrega } from './screens/entrega';
import Tooltip from '@atlaskit/tooltip';
import { FaPrint, FaRegEdit } from 'react-icons/fa';
import ImpressaoPedidoVenda from '~/components/ImpressaoPedidoVenda';

export const PedidoVendaContent: React.FC = () => {
  const { setLoading } = useContext(loadingContext);

  const {
    onRowClick,
    onNewData,
    formPedidoVenda: { getValues },
    showSearch,
    setShowSearch,
    isUpdate,
    setIsUpdate,
    currentTab,
    setCurrentTab,
    resetFormData,
    handlePrint,
    onSave,
  } = usePedidoVenda();

  const [isLoadingAtualizarStatus, setIsLoadingAtualizarStatus] =
    useState(false);
  const [isLoadingImprimir, setIsLoadingImprimir] = useState(false);

  const [showModal, setShowModal] = useState(false);

  // Botão Customizável para Atualizar Status
  // const ButtonAtualizarStatus = () => {
  //   return (
  //     <Tooltip position="bottom" content="Atualizar Status">
  //       <ButtonForm
  //         onClick={() => console.log('AtualizarStatus')}
  //         disabled={isLoadingAtualizarStatus || false}
  //         className="reprocess"
  //       >
  //         {isLoadingAtualizarStatus === true ? (
  //           <Spinner animation="border" variant="light" size="sm" />
  //         ) : (
  //           <>
  //             <FaRegEdit fontSize="1.25rem" color="#fff" />
  //             Atualizar Status
  //           </>
  //         )}
  //       </ButtonForm>
  //     </Tooltip>
  //   );
  // };

  const ButtonImprimir = () => {
    return (
      <Tooltip position="bottom" content="Imprimir">
        <ButtonForm
          onClick={() => setShowModal(!showModal)}
          disabled={isLoadingImprimir || false}
          className="imprimir"
        >
          {isLoadingImprimir === true ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <>
              <FaPrint fontSize="1.25rem" color="#fff" />
              Imprimir
            </>
          )}
        </ButtonForm>
        <ImpressaoPedidoVenda
          show={showModal}
          handleShow={setShowModal}
          handlePrint={handlePrint}
        />
      </Tooltip>
    );
  };

  return (
    <Container>
      {showSearch && (
        <Search codTela={302} newData={onNewData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={302}
          title="Pedido de Venda"
          codigoRegistro={[
            {
              value: getValues('num_pedido'),
              des_campo: 'Código',
            },
            {
              value: getValues('des_tipo_status'),
              des_campo: 'Status',
            },
          ]}
          onSave={onSave}
          isUpdate={isUpdate}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          onNew={onNewData}
          onDelete={() => null}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isSave={false}
          isDelete={false}
          // customButtons={<ButtonAtualizarStatus />}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          >
            {isUpdate && <ButtonImprimir />}
          </div>
          <Tabs
            id="controlled-produto"
            activeKey={currentTab}
            onSelect={(tab: any) => setCurrentTab(tab)}
          >
            <Tab eventKey="pedido" title="Pedido">
              <Pedido />
            </Tab>
            <Tab eventKey="financeiro" title="Financeiro">
              <Financeiro />
            </Tab>
            <Tab eventKey="entrega" title="Entrega">
              <Entrega />
            </Tab>
          </Tabs>
        </FormDefault>
      )}
    </Container>
  );
};
